export const GroupingModeModule = {
  namespaced: true,
  state: () => ({
    enabled: false,
    elements: [],
    counter: 0
  }),
  mutations: {
    togle(state) {
      state.enabled = !state.enabled;
    },
    resetElements(state) {
      state.elements = [];
    },
    addElement(state, element) {
      state.elements.push(element);
      state.counter += 1;
    },
    removeElement(state, element) {
      state.elements = state.elements.filter((elem) => {
        return elem != element;
      });
      state.counter -= 1;
    },
    resetCounter(state) {
      state.counter = 0
    },
    reset(state) {
      state.elements = [];
      state.enabled = false;
      state.counter = 0
    }
  },
  actions: {
    togle(context) {
      context.commit("togle");
      context.commit("resetElements");
      context.commit("resetCounter");
    },
    addElement(context, element) {
      context.commit("addElement", element);
    },
    removeElement(context, element) {
      context.commit("removeElement", element);
    },
    reset(context, element) {
      context.commit("reset");
    },
    togleElement(context, element) {
      if (context.state.elements.includes(element)) {
        context.commit("removeElement", element);
      } else {
        context.commit("addElement", element);
      }
    },
  },
  getters: {
    isEnabled: (state) => {
      return state.enabled;
    },
    elements: (state) => {
      return state.elements;
    },
    counter: (state) => {
      return state.counter;
    },
  },
};
