import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    name: 'home',
    path: "/",
    component: () => import("../pages/HomePage"),
    redirect: { name: 'voyages' }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/LoginPage"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../pages/LoginPage"),
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import("../pages/LoginPage"),
  },
  {
    name: "organizations",
    path: "/organizations",
    component: () => import("../pages/OrganizationsPage"),
  },
  {
    name: "organization",
    path: "/organizations/:organizationId",
    component: () => import("../pages/OrganizationPage"),
  },
  {
    name: "autos",
    path: "/autos",
    component: () => import("../pages/AutosPage"),
  },
  {
    name: "auto",
    path: "/autos/:autoId",
    component: () => import("../pages/AutoPage"),
  },
  {
    name: "drivers",
    path: "/drivers",
    component: () => import("../pages/DriversPage"),
  },
  {
    name: "driver",
    path: "/drivers/:driverId",
    component: () => import("../pages/DriverPage"),
  },
  {
    name: "voyages",
    path: "/voyages",
    component: () => import("../pages/VoyagesPage"),
  },
  {
    name: "voyage",
    path: "/voyages/:voyageId",
    component: () => import("../pages/VoyagePage"),
  },
  {
    path: "/home",
    component: () => import("../pages/HomePage"),
  },
  {
    path: "/landing",
    component: () => import("../pages/LandingPage"),
  },
  {
  name: 'Offer',
    path: "/offer",
    component: () => import("../pages/OfferPage"),
  },
  {
    name: 'plan',
    path: "/plans",
    component: () => import("../pages/PlanPage"),
  },
  {
    path: "/payment/:planKind",
    name: "payment",
    component: () => import("../pages/PaymentPage"),
    props: true,
  },
  {
    name: 'user',
    path: "/user",
    component: () => import("../pages/UserPage"),
  },
  {
    path: '/:catchAll(.*)',
    component: () => import("../pages/NotFoundPage"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
