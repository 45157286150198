
export const ErrorsMessageModule = {
  namespaced: true,
  state: () => ({
    message: null,
  }),
  mutations: {
    setMessage(state, message) {
      state.message = message;
    },
    resetMessage(state) {
      state.message = null;
    },
  },
  getters: {
    message: (state) => {
      return { message: state.message };
    },
  },
};
