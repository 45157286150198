import { createStore } from "vuex";
import { AuthStoreModule } from "@/store/modules/AuthStoreModule";
import VuexPersistence from "vuex-persist";
import { ErrorsMessageModule } from "./modules/ErrorsMessageModule";
import { GroupingModeModule } from "./modules/GroupingModeModule";
import createMutationsSharer from "vuex-shared-mutations";

const vuexPersistence = new VuexPersistence({
  reducer: (state) => ({
    auth: {
      accessToken: state.auth.accessToken,
      client: state.auth.client,
      uid: state.auth.uid,
      user: state.auth.user,
    },
  }),
});
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: AuthStoreModule,
    errorsMessage: ErrorsMessageModule,
    groupingMode: GroupingModeModule,
  },
  plugins: [
    vuexPersistence.plugin,
    createMutationsSharer({
      predicate: ["auth/setAccessCredentials", "auth/resetAccessCredentials"],
    }),
  ],
});
