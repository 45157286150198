/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import vue3GoogleLogin from 'vue3-google-login'


const app = createApp(App).use(Quasar, quasarUserOptions);
app.use(router);
app.use(store);
app.use(vue3GoogleLogin, {
  clientId: '322112561114-lo4vepj5arvu1emnoa4d5aihhei1rgov.apps.googleusercontent.com'
})
app.mount("#app");
/* eslint-disable */