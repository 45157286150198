
<template>
  <div>
    <q-layout view="hHh lpR fFf">
      <q-header elevated color="red" class="bg-primary text-white">
        <q-toolbar>
          <q-btn dense flat round icon="menu" @click="toggleLeftDrawer" />

          <q-toolbar-title style="height: 35px">
            <router-link
              :to="{
                name: `voyages`,
              }"
              style="text-decoration: none; color: inherit"
            >
              <img width="35" :src="logo" />
            </router-link>
          </q-toolbar-title>
        </q-toolbar>
      </q-header>

      <q-drawer show-if-above v-model="leftDrawerOpen" side="left" bordered>
        <div class="q-pa-md" style="max-width: 250px">
          <q-list bordered padding class="rounded-borders text-primary">
            <q-item
              to="/voyages/"
              id="voyage-menu-link"
              clickable
              active-class="my-menu-link"
            >
              <q-item-section avatar>
                <q-icon name="mdi-truck-delivery" />
              </q-item-section>

              <q-item-section>Поездки</q-item-section>
            </q-item>
            <q-item
              to="/organizations/"
              id="organization-menu-link"
              clickable
              active-class="my-menu-link"
            >
              <q-item-section avatar>
                <q-icon name="mdi-domain" />
              </q-item-section>

              <q-item-section>Организации</q-item-section>
            </q-item>
            <q-item
              to="/autos/"
              id="auto-menu-link"
              clickable
              active-class="my-menu-link"
            >
              <q-item-section avatar>
                <q-icon name="mdi-truck" />
              </q-item-section>

              <q-item-section>Автомобили</q-item-section>
            </q-item>
            <q-item
              to="/drivers/"
              id="driver-menu-link"
              clickable
              active-class="my-menu-link"
            >
              <q-item-section avatar>
                <q-icon name="mdi-card-account-details" />
              </q-item-section>

              <q-item-section>Водители</q-item-section>
            </q-item>
            <q-separator spaced />
            <q-item
              to="/plans/"
              id="plan-menu-link"
              clickable
              active-class="my-menu-link"
            >
              <q-item-section avatar>
                <q-icon name="mdi-account" />
              </q-item-section>

              <q-item-section>Подписка</q-item-section>
              <q-item-section avatar>
                <a
                  href="/logout"
                  style="text-decoration: none; color: inherit"
                  v-on:click.prevent="logoutAndRedirect"
                >
                  <q-icon name="mdi-logout" />
                </a>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </q-drawer>

      <q-page-container>
        <slot />
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import { ref } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";

export default {
  name: "BaseLayout",
  setup() {
    const leftDrawerOpen = ref(false);
    const router = useRouter();
    return {
      leftDrawerOpen,
      store,
      toggleLeftDrawer() {
        leftDrawerOpen.value = !leftDrawerOpen.value;
      },
      logoutAndRedirect() {
        store.dispatch("auth/logout");
        router.push("/login");
      },
    };
  },
  computed: {
    logo() {
      return require("@/assets/img/conus.png");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~quasar/src/css/variables";

.base-layout {
  background: #fffdfd;
}

.wrapper {
  width: 1300px;
  margin: 50px auto;
}
.my-menu-link {
  color: white;
  background: #00d1b2;
}
</style>