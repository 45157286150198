<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import BaseLayout from "./layouts/BaseLayout.vue";

export default {
  name: "LayoutDefault",

  components: {
    BaseLayout,
  },
};
</script>
