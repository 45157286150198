import "./styles/quasar.sass";
import lang from "quasar/lang/en-US.js";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/mdi-v6/mdi-v6.css";

import Notify from 'quasar/src/plugins/notify/Notify.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';;

// To be used on app.use(Quasar, { ... })
export default {
  plugins: {
    Notify,
    Loading,
    Dialog
  },
  config: {
    brand: {
      primary: "#1976d2",
      secondary: "#26A69A",
      accent: "#00d1b2",

      dark: "#1d1d1d",

      positive: "#21BA45",
      negative: "#C10015",
      info: "#31CCEC",
      warning: "#F2C037",
    },
    notify: {
      /* look at QuasarConfOptions from the API card */
    },
  },
  lang: lang,
};
