
export const AuthStoreModule = {
  namespaced: true,
  state: () => ({
    accessToken: null,
    uid: null,
    client: null,
    user: null,
    isAuthorizationProcessing: false,
  }),
  mutations: {
    setAccessCredentials(state, accessCredentials) {
      state.accessToken = accessCredentials["accessToken"] || state.accessToken;
      state.uid = accessCredentials["uid"] || state.uid;
      state.client = accessCredentials["client"] || state.client;
    },
    resetAccessCredentials(state) {
      state.accessToken = null;
      state.uid = null;
      state.client = null;
    },
    setUser(state, user) {
      state.user = user;
    },
    setAuthorizationProcessing(state, val) {
      state.isAuthorizationProcessing = val;
    },
  },
  getters: {
    accessCredentials: (state) => {
      return {
        accessToken: state.accessToken,
        uid: state.uid,
        client: state.client,
      };
    },
    isAuthenticated: (state) => !!state.user,
  },
  actions: {
    logout(context) {
      context.commit("setUser", null);
      context.commit("resetAccessCredentials");
    },
  },
};
